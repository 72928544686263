import React from 'react'
import styled from 'styled-components';

export default function WeeklyDates(props: any) {
    const { dates, currentWeek } = props;
    console.log(dates);    
  return (
    <WeeklyDateContainer>
        <WeeklyDateText>
        This Week ({currentWeek}) Will Run from <br />{dates[0].slice(5)} - {dates[1].slice(5)}:
        </WeeklyDateText>
    </WeeklyDateContainer>
  )
}

const WeeklyDateContainer = styled.div`
margin-top: -10px;
display: flex;
align-items: center;
justify-content: center;
margin-left: auto;
margin-right: auto;
background-color: brown;
border-radius: 3px;
width: 95%;
margin-bottom: 40px;
`
const WeeklyDateText = styled.h3`
color: white;
`